import HeroBannerProps from "./HeroTypes"
import { getCloudinaryImage } from "../Common/Cloudinary"

/**
 * This function will take content from contentful, and return the content needed by all hero banners.
 *
 * @param content
 */
const getBannerProps = (content, href?): HeroBannerProps => {
  // Get the default hero image
  let image = getCloudinaryImage(null)
  // replace with real image if this exists
  if (content.heroImage !== undefined && content.heroImage !== null) image = getCloudinaryImage(content.heroImage[0])
  if (content.image !== undefined && content.image !== null) image = getCloudinaryImage(content.image[0])

  const heroBannerProps: HeroBannerProps = {
    title: "",
    subTitle: "",
    ingress: "",
    // videoUrl: "", // sett to null for no video.
    image: image,
    background: "",
    createdAt: content.createdAt,
    updatedAt: content.updatedAt,
    author: "",
    href: href,
  }

  // Test for undefined or null values in the source

  if (content.title !== undefined && content.title !== null) heroBannerProps.title = content.title || "Tittel mangler"
  if (content.subtitle !== undefined && content.subtitle !== null) heroBannerProps.subTitle = content.subtitle || ""
  if (content.videoUrl !== undefined && content.videoUrl !== null) heroBannerProps.videoUrl = content.videoUrl
  if (content.ingress !== undefined && content.ingress !== null) heroBannerProps.ingress = content.ingress.ingress || ""
  if (content.backgroundColor !== undefined && content.backgroundColor !== null)
    heroBannerProps.background = content.backgroundColor || "bg-lok-500"
  if (content.createdAt !== undefined && content.createdAt !== null) heroBannerProps.createdAt = content.createdAt
  if (content.updatedAt !== undefined && content.updatedAt !== null) heroBannerProps.updatedAt = content.updatedAt
  if (content.createdAtModified !== undefined && content.createdAtModified !== null)
    heroBannerProps.createdAtModified = content.createdAtModified
  if (content.author !== undefined && content.author !== null)
    heroBannerProps.author = content.author.name || "Redaksjonen"

  return heroBannerProps
}
export default getBannerProps
