/**
 * Type definision for images stored in Cloudinary
 * @param imageId Unique image id as provided by cloudinary
 * @param imagePublicId The public id for use by Cloudinary's react Image component
 * @param imageUrl the unsecure, http address of the image
 * @param imageSecureUrl the secure, https address of the image
 * @param imageWidth the width of the image in pixels
 * @param imageHeight the height of the image in pixels
 * @param imageAlt the alternative text of use by image/figure tags
 * @param imageCaption the caption text to be applied to the image
 * @param imageCredits the credits and/or author of the image
 */
export type CloudinaryImage = {
  imageId: string
  imagePublicId: string
  imageUrl: string
  imageSecureUrl: string
  imageWidth: string
  imageHeight: string
  imageFormat: string
  imageAlt: string
  imageCredits?: string
  imageCaption?: string
}

/**
 * Denne komponenten tar i mot følgende bilde fra en Graphql spørring, og returnerer objektet CloudinaryImage
 *  
 * @param data Image {
        id
        public_id
        context {
          custom {
            Credits
            alt
            caption
          }
        }
        url
        secure_url
        width
        height
        format
      }
 * @returns CloudinaryImage
 */
export const getCloudinaryImage = (data): CloudinaryImage => {
  // data might be null, in which case we shall return a default image
  const image: CloudinaryImage = {
    imageId: "fc312e501caae3f41b951d0d8258f8e5",
    imagePublicId: "Logo/GH-logosymbol_RGB_dd64eu",
    imageUrl: "http://res.cloudinary.com/gartnerhallen/image/upload/v1612338539/Logo/GH-hovedlogo_RGB_e8pqxu.svg",
    imageSecureUrl:
      "https://res.cloudinary.com/gartnerhallen/image/upload/v1612338539/Logo/GH-hovedlogo_RGB_e8pqxu.svg",
    imageHeight: "545",
    imageWidth: "1200",
    imageFormat: "svg",
    imageAlt: "Gartnerhallens Logo",
    imageCaption: "",
    imageCredits: "Gartnerhallen",
  }

  if (data) {
    image.imageId = data.id
    image.imagePublicId = data.public_id
    image.imageUrl = data.url
    image.imageSecureUrl = data.secure_url
    image.imageHeight = data.height
    image.imageWidth = data.width
    image.imageFormat = data.format
    if (data.context !== undefined && data.context !== null) {
      if (data.context.custom !== undefined && data.context.custom !== null) {
        image.imageAlt = data.context.custom.alt || "Et bilde fra Gartnerhallen"
        image.imageCaption = data.context.custom.caption || ""
        image.imageCredits = data.context.custom.Credits || "Gartnerhallen"
      }
    }
  }

  return image
}
