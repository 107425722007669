import { Link } from "gatsby-plugin-react-i18next"
import { RelatedCardSquareGraphics } from "../Hero/HeroImageHandlers"
import { TypographyRelatedCardTitle, TypographyRelatedCardDate } from "../Styling/Style"
import { useIsAuthenticated } from "@azure/msal-react"
import HeroBannerProps from "../Hero/HeroTypes"
import { getCloudinaryImage } from "../Common/Cloudinary"
import { toDisplayDate } from "../Common/DateConverter"

/**
 * @description: Tegner kort
 * @param props Innput
 */
const CommonCard = (props) => {
  // const { accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  // Get the default hero image
  let image = getCloudinaryImage(null)
  // replace with real image if this exists
  if (props.data.heroImage !== undefined && props.data.heroImage !== null)
    image = getCloudinaryImage(props.data.heroImage[0])

  const heroProps: HeroBannerProps = {
    image: image,
    videoUrl: props.data.videoUrl,
  }

  const title = props.data.title || props.data.topic || "Title missing"
  const url = "/" + props.url + "/" + props.data.slug + "/"
  const category = props.data.category?.category || " "
  // const categoryUrl = "/nb/kategori/" + props.data.category?.slug

  let date = props.data.createdAt || ""
  if (props.data.updatedAt !== undefined && props.data.updatedAt !== null) date = props.data.updatedAt
  if (props.data.createdAtModified !== undefined && props.data.createdAtModified !== null)
    date = props.data.createdAtModified

  const prettyDate = toDisplayDate(date)

  return (
    <div className="m-10 lg:max-w-xs">
      <div>
        <RelatedCardSquareGraphics {...heroProps} />
      </div>
      <div className={TypographyRelatedCardDate + " mt-6"}>
        {/* <time dateTime="DD. MMM, YYYY">{props.data.createdAt}</time>{" "} */}
        {prettyDate} &nbsp;&nbsp;&nbsp;&nbsp;
        {props.data.category ? (
          // Enable linking to category page once this has been created
          // <Link to={categoryUrl} className="inline-block text-squash-500">
          <span className="inline-block text-squash-500">#{category}</span>
        ) : (
          // </Link>
          <></>
        )}
      </div>
      <div>
        {/* Antatt lesetid var ikke ønskelig i flg design: */}
        {/* <span>Antatt lesetid: {props.data.body.childMdx.timeToRead} minutter</span> */}
      </div>
      <div className="mt-6">
        {props.data.public ? (
          <>
            <Link to={url} className={TypographyRelatedCardTitle}>
              {title}
            </Link>
          </>
        ) : isAuthenticated ? (
          <>
            <Link to={url} className={TypographyRelatedCardTitle}>
              {title}
            </Link>
          </>
        ) : props.data.public === undefined ? (
          <>
            <Link to={url} className={TypographyRelatedCardTitle}>
              {title}
            </Link>
          </>
        ) : (
          <>
            <Link to="/minside/" className={TypographyRelatedCardTitle}>
              {title} (For innlogget brukere)
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

export default CommonCard
