export const HeroTitle = (props) => (
  <h1 className="font-silksans text-sort-900 font-normal text-header48 lg:text-header72  text-center" {...props} />
)
export const HeroTitle96 = (props) => (
  <h1 className="font-silksans text-sort-900 font-normal lg:text-header96 text-header56 text-center" {...props} />
)
export const HeroTitleFarmer = (props) => (
  <h1
    className="font-silksans text-squash-500 font-normal text-header56  sm:text-header56 md:text-header40 lg:text-header48 xl:text-header72 2xl:text-header96  text-center"
    {...props}
  />
)

export const HeroAboutTitle = (props) => (
  <h2
    className="font-silksans text-sort-900 font-normal text-header56 lg:text-header72 2xl:text-header96 text-center"
    {...props}
  />
)
export const HeroTitleTopics = (props) => (
  <h1
    className="font-silksans text-sort-900 font-normal lg:text-header96 text-header48 md:text-header56 text-center"
    {...props}
  />
)

export const HeroSubtitle = (props) => (
  <h2 className="font-silksans  text-squash-500 font-normal text-header40 lg:text-header48 text-center" {...props} />
)
export const HeroSubtitleFarmer = (props) => (
  <h2
    className="font-silksans  text-squash-500 font-normal text-header40   md:text-header24 lg:text-header32  xl:text-header48 text-center"
    {...props}
  />
)

export const HeroTitleProducts = (props) => (
  <h1
    className="font-silksans text-sort-900 font-normal xl:text-header96 md:text-header72 text-header56 text-center"
    {...props}
  />
)

export const HeroTitleForProducers = (props) => (
  <h2
    className="font-silksans text-sort-900 font-normal text-header56 sm:text-header72 md:text-header56 lg:text-header72 2xl:text-header96 text-center"
    {...props}
  />
)
