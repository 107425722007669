import { graphql } from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MsalAuthenticationTemplate, useIsAuthenticated } from "@azure/msal-react"
import getBannerProps from "../components/Hero/getHeroBannerProps"
import HeroInstructionPages from "../components/Hero/HeroInstructionPages"
import MyPageLayout from "../components/Layout/MyPageLayout"
import { RedirectRequest } from "@reach/router"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../components/Security/ErrorComponent"
import { Loading } from "../components/Security/Loading"
import { loginRequest } from "../components/Security/authConfig"
/**
 * @description Denne skal vise portrett intervjue med bonde.
 * Det skal også være en faktaboks. Dvs at vi må lage faktaboks som en innholdstype. Dvs at vi må lage en faktabox.
 * Så skal vi vise relaterte innhold. Dvs andre bonde objekter. sorter på nyeste først. inntil 3 stk.
 * @param param0
 * 
const ComponentName = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>
 */

const InstructionTemplateContent = ({ location, data }) => {
  // const isAuthenticated = useIsAuthenticated()
  // const mainPath = getMainPath("veiledning")

  const { contentfulInstruction: content } = data

  // Parse the content and create the props needed by SEO component.
  // const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content, location.href)

  console.log("test")
  // console.log(isAuthenticated)
  console.log(!content.public)
  console.log(heroBannerProps)

  return (
    <>
      {/* <SEO {...helmetProps} /> */}

      <MyPageLayout title="">
        <main>
          {!content.public ? (
            <article>
              <div className="bg-white ">
                <HeroInstructionPages {...heroBannerProps} />
                <div className="relative  mx-auto px-4 sm:px-6 lg:px-8  mb-48">
                  <div className="mx-auto max-w-screen md:max-w-2xl px-5 md:px-8 lg:max-w-3xl  xl:max-w-116 mb-48">
                    <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
                  </div>
                </div>
              </div>
            </article>
          ) : (
            <>Ikke noe innhold å vise</>
          )}
        </main>

        {/* KODE SOM VIRKER MED MYPAGELAYOUT:
       <main>
        
        {!content.public && isAuthenticated ? (
          <article>
            <div className="bg-white overflow-hidden  2xl:w-176 mx-auto ">
              <HeroInstructionPages {...heroBannerProps} />
              <div className="relative  mx-auto px-4 sm:px-6 lg:px-8  mb-48">
                <div className="mx-auto max-w-screen pl-4 md:px-8 lg:px-16 xl:mx-16 2xl:mx-48 ">
                  <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
                </div>
              </div>
            </div>
          </article>
        ) : (
          <>Ikke noe innhold å vise</>
        )}
      </main> */}
      </MyPageLayout>
    </>
  )
}

export const instructionQuery = graphql`
  query instructionsQuery($slug: String!) {
    contentfulInstruction(slug: { eq: $slug }, node_locale: { eq: "nb-NO" }) {
      id
      public
      contentful_id
      slug
      node_locale
      metadataDescription
      title
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      createdAt(formatString: "DD:MM:YYY hh:mm:ss")
      author {
        name
        lastName
        firstName
      }
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          body
          timeToRead
        }
      }
      heroImage {
        id
        public_id
        context {
          custom {
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
    }
  }
`

function InstructionTemplate({ location, data }) {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <InstructionTemplateContent location={location} data={data} />
    </MsalAuthenticationTemplate>
  )
}

export default InstructionTemplate
